.default-form {
    [data-loading-overlay] {
        opacity: 0;
        transition: opacity 0.05s;
    }

    &.is-submitting {
        [data-button-content] {
            opacity: 0;
        }
        [data-spinner] {
            display: block !important;
        }
        [data-loading-overlay] {
            opacity: 1;
        }
    }
}
