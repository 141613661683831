.side-panel {

    &__inner {
        width: 100%;
        right: 0;
        top: 0;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

        @include from(m) {
            width: calc(66.6667% + #{gutter(m)/(1/3)});
        }
        @include from(mp) {
            width: calc(66.6667% + #{gutter(mp)/(1/3)});
        }
        @include from(l) {
            width: calc(50% + #{gutter(l)/2});
        }
        @include from(lp) {
            width: calc(50% + #{gutter(lp)/2});
        }
        @include from(xl) {
            width: calc(50% + #{gutter(xl)/2});
        }

        .u-wrap {
            @include from(m) {
                padding-left: gutter(m);
            }
            @include from(mp) {
                padding-left: gutter(mp);
            }
            @include from(l) {
                padding-left: gutter(l);
            }
            @include from(lp) {
                padding-left: gutter(lp);
            }
            @include from(xl) {
                padding-left: gutter(xl);
            }
        }
    }
    
    &__close-button {
        top: 25px;
        
        @include from(m) {
            top: 35px;
        }
        @include from(l) {
            top: 35px;
        }
    }
}
