/*
==========================
Fonts
** Remember to configure font families for Tailwind in src/tailwind.config.js
==========================
 */
$fontSizeBase: 16px;
$lineHeightBase: (22/16);
$fontDefault: "GT America", "Helvetica Neue", Helvetica, Arial, sans-serif;
//$fontDefault: "adobe-caslon-pro", Georgia, Cambria, "Times New Roman", Times, serif;

/*
==========================
Colors
** Remember to configure colors for Tailwind in src/tailwind.config.js
==========================
 */
$colorBlack: #000000;
$colorWhite: #ffffff;

$colorWarm800: #3C3A36;
$colorWarm700: #85817A;
$colorWarm600: #D1CFC7;
$colorWarm500: #DDDBD5;
$colorWarm400: #ECEAE4;
$colorCool500: #C4C4C4;
$colorCool400: #E6E6E6;
$colorCream500: #ECE8E1;
$colorCream400: #F6F2EA;
$colorCream300: #FFFBF4;
$colorOlive: #737A70;
$colorPurple800: #580E4D;
$colorPurple500: #A1128B;
$colorGray: #666666;
$colorRed: #ED1C24;

$colorText: $colorWarm800;
$colorBackground: $colorWhite;

/*
==========================
Breakpoints
** Remember to configure breakpoints for Tailwind in tailwind.config.js!**
==========================
 */

$small: 375px;
$smallPlus: 540px;
$medium: 750px;
$mediumPlus: 980px;
$large: 1200px;
$largePlus: 1420px;
$xlarge: 1750px;

$breakpoints: (
    s: $small,
    sp: $smallPlus,
    m: $medium,
    mp: $mediumPlus,
    l: $large,
    lp: $largePlus,
    xl: $xlarge,
);

/*
==========================
Grid settings
==========================
 */

$gutterMap: (
    min: 16px,
    s: 16px,
    sp: 24px,
    m: 24px,
    mp: 32px,
    l: 48px,
    lp: 56px,
    xl: 64px
);

$outerMarginsMap: (
    min: 16px,
    s: 16px,
    sp: 24px,
    m: 48px,
    mp: 48px,
    l: 56px,
    lp: 88px,
    xl: 164px
);

$maxContentWidth: 1720px;

// If different max widths are needed, comment this in and comment the variable above out.
//
// $maxContentWidth: (
//     min: 1280px,
//     s: 1280px,
//     sp: 1280px,
//     m: 1280px,
//     mp: 1280px,
//     l: 1280px,
//     lp: 1440px,
//     xl: 1600px
// );

$grid-breakpointMap: $breakpoints;
$grid-breakpoints: (s, sp, m, mp, l, lp, xl);
$grid-columns: 12;
$grid-outer-margins: $outerMarginsMap;
$grid-gutters: $gutterMap;
$grid-flexbox: true;
$grid-pull-columns: 6;
$grid-push-columns: 8;
$grid-use-long-classnames: false;
$grid-use-em: false;
$grid-max-width: $maxContentWidth; //Should include support for multiple max-widths at different breakpoings. See NAF Digital
//$grid-max-width-map: $maxContentWidth; // Optional. Use if different max widths are needed at different breakpoints
