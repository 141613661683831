.listbox {
    &__toggle {
        @media(hover: hover) {
            transition: color 0.3s;
            &:hover {
                color: $colorPurple500;
            }
        }
        &:active,
        &[aria-expanded="true"] {
            color: $colorPurple500;
        }
    }
    &__menu {
        box-shadow: 0px 4px 20px rgba(60, 58, 54, 0.2);
        width: 320px;
        max-width: calc(100vw - 32px);
        max-height: 500px;
        @include scrollable;
    }
}
