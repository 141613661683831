.webbooking-apptypes {
    &__infobtn {
        &-icon {
            width: 24px;
            height: 24px;
            [aria-expanded="true"] & {
                transform: scale(1, -1);
            }
        }
        &-open {
            [aria-expanded="true"] & {
                display: none;
            }
        }
        &-close {
            [aria-expanded="false"] & {
                display: none;
            }
        }
    }
}
