.wysiwyg {

    > * {
        margin-bottom: 1.4em;

        &:last-child {
            margin-bottom: 0;
        }
    }

    p, ul, ol {
        strong, b {
            font-weight: 500;
        }
        em, i {
            font-style: italic;
        }
    }

    a:not([class*="link"]) {
        text-decoration: underline;
        .using-mouse & {
            transition: text-decoration-color 0.3s $easeOutCubic;
        }
        &:active,
        .using-mouse &:hover {
            text-decoration-color: transparent;
        }
    }

    h2, h3, h4, h5, h6 {
        margin-bottom: 0.75em;
        
        &:not(:first-child) {
            margin-top: 1.25em;
        }
    }

    li {
        position: relative;
        padding-left: 1.1em;
    }

    ul li::before {
        content: '●';
        display: block;
        position: absolute;
        left: 0;
        top: -0.1em;
        font-size: 0.8em;
    }

    ol {
        counter-reset: list;
    }

    ol li {
        counter-increment: list;
        &::before {
            content: counter(list) ".";
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    table {
        width: 100%;
        font-size: 18px;
        line-height: 24px;
        font-family: $fontDefault;
        margin-bottom: 2em;
        
        @include from(m) {
            margin-bottom: 2.8em;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
    
    td, th {
        border: 2px solid $colorWarm800;
        padding: 16px 15px 14px 15px;
    }
    
    th {
        font-weight: 500;
        background: $colorWarm500;
    }
}
