// Missing something? It's probably been converted to a custom Tailwind utility
// Check ./src/css/tailwind/utilities

// There's also a chance the utility class you're looking for has been removed, because its use case is easily covered by default utilities in Tailwind
// https://tailwindcss.com/docs

.u {
    &-wrap {
        width: 100%;
        max-width: maxWidth() + (outerMargin()*2);
        padding-left: outerMargin();
        padding-right: outerMargin();
        margin-left: auto;
        margin-right: auto;

        @each $breakpoint in map-keys($breakpoints) {
            @include from($breakpoint) {
                padding-left: outerMargin($breakpoint);
                padding-right: outerMargin($breakpoint);
                max-width: maxWidth($breakpoint) + (outerMargin($breakpoint)*2);
            }
        }
    }

    &-hit {
        &::before {
            content: "";
            display: block;
            width: 44px;
            height: 44px;
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -22px 0 0 -22px;
        }
    }
    &-menu-item-hit {
        &::before {
            content: "";
            display: block;
            width: calc(100% + 16px);
            height: calc(100% + 24px);
            position: absolute;
            left: 50%;
            top: 50%;
            //margin: -22px 0 0 -22px;
            transform: translateX(-50%) translateY(-50%);
            
            @include from(mp) {
                width: calc(100% + 24px);
            }
            @include from(mp) {
                width: calc(100% + 32px);
            }
        }
    }
}
