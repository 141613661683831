.checkout-step {
    @include until(m) {
        padding-left: 0 !important;
        padding-right: 0 !important;
    } 
    
    &__col {
        @include until(m) {
            padding-left: 0 !important;
            padding-right: 0 !important;
        } 
    }
    
    &__inner {
        padding-left: outerMargin();
        padding-right: outerMargin() * 2;
        
        @include from(s) {
            padding-left: outerMargin('s');
            padding-right: outerMargin('s') * 2;
        }
        @include from(sp) {
            padding-left: outerMargin('sp');
            padding-right: outerMargin('sp') + 30px;
        }
        @include from(m) {
            
        }
    }
    
    &__status {
        width: 32px;
        
        @include from(m) {
            width: 48px;
        }
    }
    
    &__status-bullet {
        font-feature-settings: "lnum" 1;
        width: 32px;
        height: 32px;
        
        @include from(m) {
            width: 48px;
            height: 48px;
        }
    }
    
    &__status-bar {
        width: 2px;
    }
}
