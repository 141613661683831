/* ==============================
    Basic/generic global styles
 ============================== */

body {
    min-height: calc(100% + 1px);
    width: 100%;
    overflow-x: hidden;
    //-webkit-font-smoothing: antialiased;
    //-moz-osx-font-smoothing: grayscale;
}

#main {
    background-color: $colorCream300;
}

.lazyload,
.lazyloading,
.videoload {
    opacity: 0;
    filter: blur(10px);
    transform: translateZ(0);
}

.lazyloaded,
.videoloaded {
    opacity: 1;
    filter: blur(0);
    transition-property: opacity, filter;
    transition-duration: 0.4s;
    transition-timing-function: $easeOutSine;
}

.blurhash {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
}


.no-js {
    img.lazyload {
        display: none;
    }
}

[hidden] {
    display: none !important;
}

.g-wrap {
    width: 100%;
}

th, td {
    vertical-align: top;
    text-align: left;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

input:required {
    box-shadow: none;
}

html.no-outline * {
    outline: none;
}

::-moz-selection {
    background: $colorText;
    color: $colorBackground;
}

::selection {
    background: rgba($colorText, 0.99);
    color: $colorBackground;
}

table {
    th {
        font-weight: normal;
    }

    th, td {
        vertical-align: top;
        padding: 0;
        border: none;
    }
}

button, a {
    touch-action: manipulation;
}
