.webbooking {
    &__step {
        scroll-margin-top: 140px;
    }
    &__confirm {
        &-inner {
            @include from(s) {
                padding-left: outerMargin('s') + (gutter(s) * 2);
            }
            @include from(m) {
                padding-left: outerMargin('m') + (gutter(m) * 2) - 8px; // I have no idea why 8
            }
        }
    }
}
