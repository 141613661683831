.logo {

    width: 164px;

    @include from(m) {
        width: 150px;
    }

    @include from(l) {
        width: 200px;
    }

    > * {
        @include aspect(164, 36);
    }
}
