/*

    @font-face declarations

    Paths to url() should be relative to the SASS entrypoint (base.scss), i.e. "../fonts/fontname.woff2"

 */

@layer base {

    /* GT America */
    @font-face {
        font-family: 'GT America';
        src: url('../fonts/gt-america/GT-America-Standard-Regular.woff2') format('woff2'), /* Super Modern Browsers */
             url('../fonts/gt-america/GT-America-Standard-Regular.woff') format('woff'); /* Pretty Modern Browsers */
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'GT America';
        src: url('../fonts/gt-america/GT-America-Standard-Thin.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/gt-america/GT-America-Standard-Thin.woff') format('woff'); /* Pretty Modern Browsers */
        font-weight: 200;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'GT America';
        src: url('../fonts/gt-america/GT-America-Standard-Medium.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/gt-america/GT-America-Standard-Medium.woff') format('woff'); /* Pretty Modern Browsers */
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    /*
     *	WebFont Package Licensed to Krogh Optikk AS
     */
    /* Wulkan Display Medium */
    @font-face {
        font-family: 'Wulkan Display';
        src: url('../fonts/wulkan-display/eot/Wulkan Display Medium.eot'); /* IE9 Compat Modes */
        src: url('../fonts/wulkan-display/eot/Wulkan Display Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
             url('../fonts/wulkan-display/woff2/Wulkan Display Medium.woff2') format('woff2'), /* Super Modern Browsers */
             url('../fonts/wulkan-display/woff/Wulkan Display Medium.woff') format('woff'); /* Pretty Modern Browsers */
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    /* Wulkan Display Medium Italic */
    @font-face {
        font-family: 'Wulkan Display';
        src: url('../fonts/wulkan-display/eot/Wulkan Display Medium Italic.eot'); /* IE9 Compat Modes */
        src: url('../fonts/wulkan-display/eot/Wulkan Display Medium Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
             url('../fonts/wulkan-display/woff2/Wulkan Display Medium Italic.woff2') format('woff2'), /* Super Modern Browsers */
             url('../fonts/wulkan-display/woff/Wulkan Display Medium Italic.woff') format('woff'); /* Pretty Modern Browsers */
        font-weight: 500;
        font-style: italic;
        font-display: swap;
    }

}
