/**
========================
Custom Tailwind components
https://tailwindcss.com/docs/extracting-components
========================
 */

@layer components {

    /**
        Headings
     */
    .h1-max {
        @apply font-wulkan font-medium text-56 leading-64 m:text-76 m:leading-80 l:text-106 l:leading-112 xl:text-130 xl:leading-144;
    }

    .h1 {
        @apply font-wulkan font-medium text-40 leading-48 m:text-76 m:leading-80 l:text-106 l:leading-112 xl:text-120 xl:leading-136;
    }

    .h2 {
        @apply font-wulkan font-medium text-40 leading-48 l:text-56 l:leading-64 xl:text-72 xl:leading-80;
    }

    .h3 {
        @apply font-wulkan font-medium text-32 leading-40 l:text-40 l:leading-48 xl:text-56 xl:leading-64;
    }

    .h4 {
        @apply font-thin text-34 leading-40 m:text-26 m:leading-32 l:text-36 l:leading-40;
    }

    .h5 {
        @apply font-wulkan font-medium text-24 leading-32;
    }

    .h6 {
        @apply font-wulkan font-medium text-22 leading-32;
    }

    .h-sidepanel {
        @apply font-wulkan font-medium text-24 leading-32 l:text-36 l:leading-40;
    }

    /**
        Wysiwyg copy typo
     */
    .p-large {
        @apply font-wulkan font-medium text-23 leading-32 m:text-28 m:leading-40 l:text-36 l:leading-48 xl:text-44 xl:leading-60;
    }

    .p-small {
        @apply font-wulkan font-medium text-23 leading-32 m:text-18 m:leading-24 l:text-22 l:leading-32 xl:text-26 xl:leading-36;
    }

    .p-article {
        @apply font-caslon text-20 leading-32 l:text-24 xl:text-28 xl:leading-36;
    }

    .p-intro {
        @apply font-thin text-22 leading-28 m:text-18 m:leading-24 l:text-22 l:leading-28;
    }

    /**
        Body typo
     */
    .body-regular {
        @apply text-18 leading-24;
    }

    .body-medium {
        @apply text-18 leading-24 font-medium;
    }

    .body-small {
        @apply text-16 leading-24 m:text-14 m:leading-20 l:text-16 l:leading-24;
    }

    /**
        Misc type
     */
    .desc-tag {
        @apply font-thin text-16 leading-20 uppercase text-gray;
        [class*="bg-olive"] &,
        [class*="bg-warm"] &,
        [class*="bg-gray"] &,
        [class*="bg-purple"] & {
            color: currentColor;
        }
    }

    .price-tag {
        @apply text-18 leading-28 m:text-22 l:text-24;
    }

    .member-price {
        @apply text-warm-700;
        [class*="bg-olive"] &,
        [class*="bg-warm"] &,
        [class*="bg-gray"] &,
        [class*="bg-purple"] & {
            @apply text-gray-1
        }
    }


    /**
        Adds top padding to account for the fixed, global header
     */
    .pad-top {
        @apply pt-120 m:pt-160 l:pt-200 xl:pt-220;
    }

    .map-marker {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 0;
        height: 0;

        &:after {
            content: '';
            display: block;
            width: 70px;
            height: 70px;
            margin: -50px 0 0 -35px;
            background: radial-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0));
        }

        svg {
            bottom: 0;
            left: -9px;
        }
    }

    .base-input {
        position: relative;

        label {
            @apply sr-only;
        }

        input {
            @apply text-18 leading-1 font-medium text-warm-800 bg-cream-300;
            padding-left: 10px;
            padding-right: 10px;
            width: 100%;
            height: 64px;

            &::placeholder {
                @apply text-warm-700;
            }

            &:disabled {
                opacity: 0.6;
            }

        }
    }

    .float-input {
        position: relative;

        input, label {
            @apply text-18 leading-1 font-medium;
            padding-left: 10px;
            padding-right: 10px;
        }

        label {
            cursor: text;
        }

        input {
            @apply text-warm-800 bg-cream-300;

            border-bottom: 2px solid transparent;

            [class*="bg-cream-300"] & {
                @apply bg-cream-400;
            }

            width: 100%;
            height: 64px;

            &::placeholder {
                @apply text-warm-700;
            }

            &:disabled {
                opacity: 0.6;
            }

            &:focus {
                border-bottom: 2px solid currentColor;

                [class*="bg-olive"] &,
                [class*="bg-warm"] &,
                [class*="bg-gray"] &,
                [class*="bg-purple"] & {
                    @apply border-transparent;
                }
            }
        }

        label {
            @apply text-warm-700;
            position: absolute;
            top: 24px;
            left: 0;
            opacity: 0;
            transition-property: font-size, opacity, transform;
            transition-duration: 0s;
            transition-timing-function: ease-out;
        }

        input:not(:placeholder-shown) {
            padding-top: 16px;
        }

        input:not(:placeholder-shown) + label {
            transform: translateY(-17px);
            opacity: 1;
            font-size: 14px;
            transition-duration: 0.05s;
        }
    }
    
    .textarea-input {
        textarea {
            @apply text-18 font-medium text-warm-800 bg-cream-300;
            border-bottom: 2px solid transparent;
            padding: 15px 10px;

            [class*="bg-cream-300"] & {
                @apply bg-cream-400;
            }

            width: 100%;
            height: auto;

            &::placeholder {
                @apply text-warm-700;
            }

            &:disabled {
                opacity: 0.6;
            }

            &:focus {
                border-bottom: 2px solid currentColor;

                [class*="bg-olive"] &,
                [class*="bg-warm"] &,
                [class*="bg-gray"] &,
                [class*="bg-purple"] & {
                    @apply border-transparent;
                }
            }
        }
    }

    .radio-select {

        &__bullet {
            position: relative;

            &:after {
                input:checked + & {
                    content: ' ';
                    display: block;
                    width: 14px;
                    height: 14px;
                    background: $colorWarm800;
                    border-radius: 9999px;
                    position: absolute;
                    left: 3px;
                    top: 3px;
                }
            }
        }
    }

    .checkbox {
        &__mark {
            border: 2px solid currentColor;

            svg {
                opacity: 0;
                left: 2px;
                top: 1px;
            }

            input:checked + & {
                svg {
                    opacity: 1;
                }
            }
        }

        .errors {
            margin-top: 4px;
        }
    }

    .select {
        @apply text-18 font-medium border-2 border-warm-600 bg-cream-400;
        display: block;
        height: 40px;
        padding-left: 6px;
        padding-right: 30px;
        appearance: none;
        position: relative;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 15l-.768.64.768.922.768-.922L12 15zm5.768-5.36l.64-.768-1.536-1.28-.64.768 1.536 1.28zm-10-1.28l-.64-.768-1.536 1.28.64.768 1.536-1.28zm8.464 0l-5 6 1.536 1.28 5-6-1.536-1.28zm-3.464 6l-5-6-1.536 1.28 5 6 1.536-1.28z' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: right 4px top 50%;
        background-size: 24px 24px;

        &::-ms-expand {
            display: none;
        }

        &:not(:disabled) {
            cursor: pointer;
        }
        &--large {

            font-size: 14px;
            height: 64px;
            padding-left: 20px;
            padding-right: 40px;
            background-position: right 12px top 50%;

            @include from(s) {
                font-size: 16px;
            }

            @include from(sp) {
                font-size: 18px;
                padding-left: 30px;
                padding-right: 60px;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTIgMTZsLS43NTMuNjU5Ljc1My44Ni43NTMtLjg2TDEyIDE2em03Ljc1My03LjM0MWwuNjU4LS43NTMtMS41MDUtMS4zMTctLjY1OS43NTIgMS41MDYgMS4zMTd6bS0xNC0xLjMxOGwtLjY1OS0uNzUyTDMuNTkgNy45MDZsLjY1OC43NTNMNS43NTMgNy4zNHptMTIuNDk0IDBsLTcgOCAxLjUwNiAxLjMxOCA3LTgtMS41MDYtMS4zMTh6bS01LjQ5NCA4bC03LTgtMS41MDYgMS4zMTcgNyA4IDEuNTA2LTEuMzE3eiIgZmlsbD0iIzNDM0EzNiIvPjwvc3ZnPg==");
                background-position: right 30px top 50%;
            }
        }
    }

    .errors {
        @apply text-error mt-8 text-14 leading-16 l:text-16 leading-20;
    }

    .orders-table {
        th {
            @apply bg-warm-600 font-medium;
        }

        th {
            padding: 8px 12px;
        }

        td {
            padding: 10px 12px 0 12px;
        }
    }

    .products-block-slide {
        .js & {
            display: none;

            &:first-child {
                display: flex;
            }

            @include from(m) {
                display: flex;
            }
        }
    }

    .sticky-news-col {
        @include from(m) {
            top: calc(50% - 4em);
        }
    }
    
    .sticky-news-item {
        &:hover {
            opacity: 1 !important;
        }
    }

    .sidebar-nav-btn {
        .icon-w-bg, .favourites-count, .cartbtn__count {
            transition-property: transform;
            transition-duration: 0.4s;
            transition-timing-function: $easeOutQuint;
        }

        .icon-w-bg svg {
            transition-property: opacity;
            transition-duration: 0.2s;
            transition-timing-function: linear;
        }

        &:hover {
            .icon-w-bg, .favourites-count, .cartbtn__count {
                transform: translateX(-4px);
            }

            .icon-w-bg {
                svg:last-child {
                    opacity: 1;
                }
            }
        }
    }

    .add-to-favourites-button {
        .icon-w-bg {
            transition: color 0.2s ease 0.1s;
            
            svg * {
                transition: fill 0.2s ease 0.1s;
            }
        }
        .icon-w-bg.is-selected {
            svg:last-child * {
                fill: $colorWarm800;
            }
        }

        &:hover {
            .icon-w-bg {
                color: $colorWhite;
                
                svg:last-child * {
                    fill: $colorWarm800;
                }
            }
            .icon-w-bg.is-selected {
                color: $colorWhite;
                
                svg:last-child * {
                    fill: $colorWarm800;
                }
            }
        }
    }


    .favourites-count {
        width: 24px;
        height: 24px;
        top: -4px;
        right: 2px;
    }
    
    .animation-blurred {
        transition-property: filter, opacity;
        transition-duration: 1.1s, 0.7s;
        transition-delay: 0.05s;
        transition-timing-function: $easeOutSine;
        filter: blur(20px);
        opacity: 0;
        
        &.in-view {
            filter: blur(0);
            opacity: 1;
        }
        
        .no-js & {
            filter: blur(0) !important;
            opacity: 1 !important;
        }
    }
    
    .button-glow {
        border: 1px solid rgba(255, 251, 244, 0.2);
    }
    
    .mini-icon-adjust {
        right: -4px;
        top: -7px;
    }
}
