.page-header {
    $this: &;

    &:not(.js-menu-open) {
        overflow: hidden;
    }

    @include until(m) {
        &__nav {
            &:target &-menu[hidden] {
                display: block !important;
            }
        }
    }

    &__inner {
        background: rgba($colorCream300, 0.85);
        transition: transform 0.5s $easeOutCubic;
        
        .is-hidden & {
            transform: translate(0, -100%);
            transition: transform 0.3s;
        }
        
        .js-menu-open & {
            transition: none !important;
            transform: none !important;
        }
        
        @include until(m) {
            height: 80px;
        }
    
        @include from(m) {
            height: 100px;
        }
        
    }

}
