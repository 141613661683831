/**
========================
Custom Tailwind utilities
https://tailwindcss.com/docs/adding-new-utilities
========================

All Tailwind-powered utilities need to render inside the `@layer utilities` directive.
Tailwind will automatically create responsive classes for any utilities rendered inside the `@responsive` directive.
If you need Tailwind to create pseudo-class variant classes (e.g. for :hover or :focus), use the `@variants` directive.
The `@variants` and `@responsive` directives can be combined.

https://tailwindcss.com/docs/adding-new-utilities#generating-responsive-variants
https://tailwindcss.com/docs/adding-new-utilities#generating-pseudo-class-variants

 */

@layer utilities {

    @responsive {

        .gpu {
            @include gpu();
        }

        .anti {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        .round {
            border-radius: 100%;
        }

        .full {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
        
        .fullheight {
            height: 100vh;
            @supports (--custom:property) {
                height: calc(var(--vh, 1vh) * 100);
            }
        }

        .min-fullheight {
            min-height: 100vh;
            @supports (--custom:property) {
                min-height: calc(var(--vh, 1vh) * 100);
            }
        }

        .clear {
            &:before, &:after {
                content: "";
                display: table;
            }
            &:after {
                clear: both;
            }
        }

        .center {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .center-x {
            left: 50%;
            transform: translateX(-50%);
        }

        .center-y {
            top: 50%;
            transform: translateY(-50%);
        }

        .object-cover {
            object-fit: cover;
            width: 100%;
            height: 100%;
            html.no-objectfit & {
                object-fit: none !important;
                width: auto;
                height: auto;
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: none;
                max-height: none;
                min-width: 100%;
                min-height: 100%;
            }
        }

        .object-contain {
            object-fit: contain;
            width: 100%;
            height: 100%;
            html.no-objectfit & {
                object-fit: none !important;
                width: auto;
                height: auto;
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;
                max-height: 100%;
            }
        }

        .placed-wrap {
            position: relative;
            height: 0;
        }

        .placed-image {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }

        .scrollable {
            @include scrollable();
        }

        .scrollable-x {
            @include scrollableX();
        }

        .ellipsis {
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
            max-width: 100%;
            white-space: nowrap;
        }

        .link {
            background-image: linear-gradient(currentColor, currentColor), linear-gradient(rgba($colorWarm800, 0.5), rgba($colorWarm800, 0.2));

            &[class*="text-cream"],
            [class*="text-cream"] & {
                background-image: linear-gradient(currentColor, currentColor), linear-gradient(rgba($colorCream300, 0.2), rgba($colorCream300, 0.2));
            }

            background-position: 0% 100%, 0% 100%;
            background-repeat: no-repeat;
            background-size: 0% 1px, 100% 1px;

            transition: background-size 0.3s $easeOutQuad;

            html:not(.using-touch) &:hover,
            html:not(.using-touch) a:hover & {
                background-size: 100% 1px, 100% 1px;
            }
        }

        .link-alt {
            background-image: linear-gradient(currentColor, currentColor);
            background-position: 0% 100%;
            background-repeat: no-repeat;
            background-size: 0% 1px;
            background-size: 0% #{'max(1px, 0.07em)'};

            transition: background-size 0.3s $easeOutQuad;

            @media (hover: hover) {
                &:hover,
                a:hover &,
                button:hover & {
                    background-size: 100% 1px;
                    background-size: 100% #{'max(1px, 0.07em)'};
                }
            }
        }

        .icon-24 {
            width: 24px;
            height: 24px;
        }
        
        .icon-32 {
            width: 32px;
            height: 32px;
        }
        
        .icon-arrow-long {
            width: 50px;
            height: 14px;
        }
        
        .no-js-flex {
            .no-js & {
                display: flex !important;
            }
        }
        .no-js-hidden {
            .no-js & {
                display: none !important;
            }
        }
        .no-js-relative {
            .no-js & {
                position: relative !important;
            }
        }
        .no-js-flashmessage {
            .no-js & {
                z-index: 0 !important;
                margin-top: 100px;
            }
        }
    }

}
