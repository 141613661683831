.circle-button {
    $this: &;

    &[disabled],
    &[aria-disabled] {
        opacity: 0.5;
        cursor: default;
    }

    &__bg {
        background-color: $colorWarm800;
        transition: transform .5s;
        transform: scaleY(0);
        transform-origin: top center;

        [class*="text-cream"] & {
            background-color: $colorCream300;
        }
    }

    // Theming (well not really but stuff that deals with colors)
    @media (hover: hover) {
        &:not(:disabled):not([aria-disabled="true"]):hover {
            #{$this}__wrap {
                color: $colorCream300;
            }

            #{$this}__bg {
                transform-origin: bottom center;
                transform: scaleX(1);
            }

            [class*="text-cream"] & {
                #{$this}__wrap {
                    color: $colorWarm800;
                }
            }
            
            .icon-arrow-long {
                animation: arrow-out-in 0.85s $easeInOutQuint;
            }
        }
    }

    &--fill {
        background-color: $colorWarm800;
        color: $colorWarm800;

        #{$this}__wrap {
            color: $colorCream300;
        }
        
        #{$this}__bg {
            background-color: $colorCream300;
        }

        [class*="bg-warm"] & {
            border-color: $colorCream300;
        }

        @media (hover: hover) {
            &:not(:disabled):not([aria-disabled="true"]):hover {
                #{$this}__wrap {
                    color: $colorWarm800;
                }
            }
        }
    }
}

@keyframes arrow-out-in {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(200%);
  }
  50.1% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(0);
  }
}
