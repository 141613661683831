.porterbuddy-widget {

    &.-can-fixed {
        @include from(mp) {
            position: fixed;
            width: 100%;
            bottom: 0;
            left: 0;
            z-index: 3;
        }
    }

    &__wrap {
        .-can-fixed & {
            @include from(mp) {
                width: 100%;
                max-width: maxWidth('mp') + (outerMargin('mp')*2);
                padding-left: outerMargin('mp') - gutter('mp')/2;
                padding-right: outerMargin('mp') - gutter('mp')/2;
                margin-left: auto;
                margin-right: auto;
            }

            @include from(l) {
                max-width: maxWidth('l') + (outerMargin('l')*2);
                padding-left: outerMargin('l') - gutter('l')/2;;
                padding-right: outerMargin('l') - gutter('l')/2;;
            }

            @include from(lp) {
                max-width: maxWidth('lp') + (outerMargin('lp')*2);
                padding-left: outerMargin('lp') - gutter('lp')/2;;
                padding-right: outerMargin('lp') - gutter('lp')/2;;
            }

            @include from(xl) {
                max-width: maxWidth('xl') + (outerMargin('xl')*2);
                padding-left: outerMargin('xl') - gutter('xl')/2;;
                padding-right: outerMargin('xl') - gutter('xl')/2;;
            }
        }
    }

    &__inner {
        min-height: 180px;
    }

    &__logo {
        width: 132px;
        height: 19px;
    }

    &__field {
        width: 160px;
    }

    input::placeholder {
        color: $colorWarm800;
    }
}
