.shop-item {
    
    &__img-wrap {
        padding-bottom: (1/0.8) * 100%;
        
        @include from(m) {
            padding-bottom: (1/0.67) * 100%;
        }
    }
}
