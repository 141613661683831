.button {
    $this: &;

    height: 64px;
    min-width: 64px;

    &[disabled],
    &[aria-disabled] {
        opacity: 0.5;
        cursor: default;
    }

    // Keep compact buttons smaller for the smallest screens
    &--compact {
        @include until(m) {
            height: 40px;
            min-width: 40px;
        }
    }

    // If not compact, allow it to grow larger for the biggest screens
    &:not(&--compact) {
    }

    &__wrap {
        padding: 0 7px;
    }

    &__label {
        padding: 0 22px;
    }

    &__icon {
        width: 24px;
        height: 24px;

        // Adjust spacing if the icon is next to a text label
        #{$this}__label + & {
            margin: 0 22px 0 -6px;
            top: 1px;
        }

        // If the button only contains the icon, and not a label, the icon can become a bit larger for larger screens
        &:first-child {
            @include from(m) {
                width: 32px;
                height: 32px;
            }
        }
        
        &.-icon-arrow-right {
            transition: transform 0.5s $easeOutQuint;
        }
    }

    &__bg {
        background-color: $colorWarm800;
        width: calc(100% + 1px);
        transition: transform .5s;
        transform: scaleY(0);
        transform-origin: top center;
        
        .button--fill & {
            width: 100%; // fixes issue where +1px would make the right side of border disappear
        }

        [class*="text-cream"] & {
            background-color: $colorCream300;
        }
    }

    // Theming (well not really but stuff that deals with colors)
    @media (hover: hover) {
        &:not(:disabled):not([aria-disabled="true"]):hover {
            #{$this}__wrap {
                color: $colorCream300;
            }

            #{$this}__bg {
                transform-origin: bottom center;
                transform: scaleX(1);
            }

            #{$this}__icon.-icon-arrow-right {
                transform: translateX(8px);
            }

            [class*="text-cream"] & {
                #{$this}__wrap {
                    color: $colorWarm800;
                }
            }
        }
    }

    &--fill {
        background-color: $colorWarm800;
        color: $colorWarm800;

        #{$this}__wrap {
            color: $colorCream300;
        }
        
        #{$this}__bg {
            background-color: $colorCream300;
        }

        [class*="bg-warm"] & {
            border-color: $colorCream300;
        }

        @media (hover: hover) {
            &:not(:disabled):not([aria-disabled="true"]):hover {
                #{$this}__wrap {
                    color: $colorWarm800;
                }
            }
        }
    }

    .is-submitting & {
        [data-spinner] {
            display: block !important;
        }

        [data-label] {
            visibility: hidden;
        }
    }
}
