.hero-image-single {
    
    @include until(m) {
        padding-left: 0;
        padding-right: 0;
    }
    
    &__inner {
        margin-top: -0.52em;
        
        @include until(m) {
            padding-left: 0;
            padding-right: 0;
        }
        
        @include from(m) {
            margin-top: -0.46em;
        }
        @include from(xl) {
            margin-top: -0.49em;
        }
    }
    
    &__img-wrap {
        padding-bottom: (4/3) * 100%;
        
        @include from(m) {
            padding-bottom: (3/4) * 100%;
        }
    }
}
