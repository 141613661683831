.product-filters {

    &__wrap {
        @include until(m) {
            overflow: hidden;
            overflow-y: auto;
        }
    }

    &__header {
        height: 89px;
        padding-top: 25px;
        padding-bottom: 33px;
    }

    &__count {
        width: 24px;
        height: 24px;
    }

    &__toggle-count {
        width: 28px;
        height: 28px;
        margin-right: -2px;
    }

    &__group {
        @include until(m) {
            height: calc(100% - 90px);
        }
        @include from(m) {
            min-width: 320px;
            box-shadow: 0px 4px 20px rgba(60, 58, 54, 0.2);
        }
    }

    &__items {
        @include scrollable;
        @include from(m) {
            max-height: (8 * 64) + 32px;
        }

        .is-loading:not(.is-resetting) &-spinner {
            display: block;
        }
    }

    &__item {
        &[aria-disabled="true"] {
            opacity: 0.5;
            cursor: default;
        }
        &:not([aria-disabled="true"]):active {
            background-color: $colorCool500;
        }
        @media (hover: hover) {
            transition: background-color 0.3s;
            &:not([aria-disabled="true"]):hover {
                background-color: $colorCool500;
            }
        }
    }

    &__filtertoggle {
        // hover:bg-cool-500 m:hover:bg-transparent hover:text-purple-500
        @include until(m) {
            &:active {
                background-color: $colorCool500;
            }
        }
        @media (hover: hover) {
            transition: color 0.3s, background-color 0.3s;
            &:hover {
                @include until(m) {
                    background-color: $colorCool500;
                }
                @include from(m) {
                    color: $colorPurple500;
                }
            }
        }
        @include from(m) {
            &:active,
            &[aria-expanded="true"] {
                color: $colorPurple500;
            }
        }
    }

    &__checkbox {
        width: 32px;
        height: 32px;
        svg {
            width: 24px;
            height: 24px;
            .is-active & {
                opacity: 1;
            }
        }
        span {
            .is-active & {
                opacity: 0.35;
            }
        }
    }

    &__resetbtn {
        .has-filters & {
            display: block !important;
        }
        .is-resetting & {
            [data-spinner] {
                display: block !important;
            }
            [data-label] {
                visibility: hidden;
            }
        }
    }
}
