.product-details {

    &__bg {
        width: calc(100% + #{outerMargin() * 2});
        margin-left: -(outerMargin());

        @include from(s) {
            width: calc(100% + #{outerMargin(s) * 2});
            margin-left: -(outerMargin(s));
        }

        @include from(sp) {
            width: calc(100% + #{outerMargin(sp) * 2});
            margin-left: -(outerMargin(sp));
        }

        @include from(m) {
            width: calc(100% + #{outerMargin(m) * 2});
            margin-left: -((outerMargin(m) * 2) + (gutter(m) / 2));
        }

        @include from(mp) {
            width: calc(100% + #{outerMargin(mp) * 2});
            margin-left: -((outerMargin(mp) * 2) + (gutter(mp) / 2));
        }

        @include from(l) {
            width: calc(100% - #{gutter(l)});
            margin-left: gutter(l) / 2;
        }

        @include from(lp) {
            width: calc(100% - #{gutter(lp)});
            margin-left: gutter(lp) / 2;
        }

        @include from(xl) {
            width: calc(100% - #{gutter(xl)});
            margin-left: gutter(xl) / 2;
        }
    }

}
