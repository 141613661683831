.feature-plug {
    
    &__text > div {
        @include from(l) {
            margin-left: gutter('l') * -1;
        }
        @include from(lp) {
            margin-left: gutter('lp') * -1;
        }
        @include from(xl) {
            margin-left: gutter('xl') * -1;
        }
        
        .-mirrored & {
            margin-left: 0 !important;
        }
    } 
    
    &__image.-first {
        margin-top: calc(-40px - 0.52em);
        
        .-push-image-down & {
            margin-top: 0 !important;
        }
        
        @include from(m) {
            margin-top: calc(-16px - 0.46em);
        }
        @include from(l) {
            margin-top: calc(-60px - 0.46em);
        }
        @include from(xl) {
            margin-top: calc(-60px - 0.49em);
        }
    }
    
    &__image.-second {
        margin-top: -20%;
        
        @include from(m) {
            margin-top: -6%
        }
        @include from(l) {
            margin-top: -13%
        }
    }
    
    &__image-offset {
        margin-left: outerMargin('min') * -1;
            
        @include from(s) {
            margin-left: outerMargin('s') * -1;
        }
        @include from(sp) {
            margin-left: outerMargin('sp') * -1;
        }
        @include from(m) {
            margin-left: outerMargin('m') * -1;
        }
        @include from(mp) {
            margin-left: outerMargin('mp') * -1;
        }
        @include from(l) {
            margin-left: outerMargin('l') * -1;
        }
        @include from(lp) {
            margin-left: outerMargin('lp') * -1;
        }
        @include from(xl) {
            margin-left: 0;
        }
        
        .-mirrored & {
            margin-left: 0 !important;
            margin-right: outerMargin('min') * -1;
            
            @include from(s) {
                margin-right: outerMargin('s') * -1;
            }
            @include from(sp) {
                margin-right: outerMargin('sp') * -1;
            }
            @include from(m) {
                margin-right: outerMargin('m') * -1;
            }
            @include from(mp) {
                margin-right: outerMargin('mp') * -1;
            }
            @include from(l) {
                margin-right: outerMargin('l') * -1;
            }
            @include from(lp) {
                margin-right: outerMargin('lp') * -1;
            }
            @include from(xl) {
                margin-right: 0;
            }
        }
    }
}
