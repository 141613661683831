.hero-area-twin {
    
    &__inner {
        margin-top: -0.52em;

        @include from(m) {
            margin-top: -0.46em;
        }
        @include from(xl) {
            margin-top: -0.49em;
        }
    }
    
    &__img-outer {
        
    }
    
    &__img-offset {
        .-first & {
            margin-left: outerMargin('min') * -1;
            
            @include from(s) {
                margin-left: outerMargin('s') * -1;
            }
            @include from(sp) {
                margin-left: outerMargin('sp') * -1;
            }
            @include from(m) {
                margin-left: outerMargin('m') * -1;
            }
            @include from(mp) {
                margin-left: outerMargin('mp') * -1;
            }
            @include from(l) {
                margin-left: outerMargin('l') * -1;
            }
            @include from(lp) {
                margin-left: outerMargin('lp') * -1;
            }
            @include from(xl) {
                margin-left: 0;
            }
        }
    }
    
    &__img-wrap {
        padding-bottom: (4/3) * 100%;
        
        @include from(m) {
            padding-bottom: (4/3) * 100%;
        }
    }
}
