.product-card {
    [data-image-base] {
        transition-duration: 0.75s;
    }

    [data-image-hover] {
        transform: scale(1.001);
        opacity: 0 !important;
        //filter: blur(8px) !important;
        //transition-property: transform, opacity, filter;
    }
    
    .icon-w-bg svg:last-child * {
        transition: fill 0.2s linear;
    }
    
    @media(hover: hover) {
        &.-has-hover:hover {
            [data-image-hover] {
                //transform: scale(1.001);
                filter: blur(0) !important;;
                opacity: 1 !important;
            }
            [data-image-base] {
                opacity: 0;
            }
        }
    }

    button {
        .icon-w-bg.is-selected {
            svg:last-child {
                transform: scale(1.1);
            }
            svg:last-child * {
                fill: $colorWarm800;
            }
        }
        
        [data-mini-icon] {
            transform: translateY(-5px);
            transition-property: transform, opacity;
            transition-duration: 0.5s, 0.1s;
            transition-timing-function: $easeOutQuint, linear;
        }
        
        [data-mini-icon-success] {
            transition-property: opacity;
            transition-duration: 0.2s;
            transition-timing-function: linear;
        }
        
        .-was-success [data-mini-icon-success] {
            opacity: 1 !important;
            transition-duration: 0s;
        }
        
        &:hover {
            .icon-w-bg {
                svg:last-child * {
                    fill: $colorWhite;
                }
            }
            [data-mini-icon] {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
}
